
var prefix = 'reuzer';
var iconName = 'certificate';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f501';
var svgPathData = `
        M838.009 0h-652.017c-18.457 0-33.437 14.979-33.437 33.437v957.127c0 18.457 14.979 33.437 33.437 33.437h652.017c18.457 0 33.437-14.979 33.437-33.437v-957.127c0-18.457-14.979-33.437-33.437-33.437zM804.573 957.127h-585.143v-890.252h585.143v890.252z
        M512.001 541.257c99.24 0 179.974-81.1 179.974-180.759s-80.733-180.776-179.974-180.776c-99.24 0-179.974 81.1-179.974 180.759s80.733 180.776 179.974 180.776zM512.001 246.595c62.36 0 113.099 51.092 113.099 113.886s-50.741 113.901-113.099 113.901-113.099-51.092-113.099-113.886 50.741-113.901 113.099-113.901z
        M347.827 678.147h328.349c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-328.349c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
        M347.827 815.020h328.349c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-328.349c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
        `

export const reuzerCertificate = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};