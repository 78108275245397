import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'

//Custom Modules
import { authentication } from './authentication.module'
import { app } from './app.module'
import { theme } from './theme.module'
Vue.use(Vuex)

//https://github.com/robinvdvleuten/vuex-persistedstate
const vuexPersist = createPersistedState({
	key: 'ReuzerProfile',
	paths: ['authentication'],
	storage: {
		getItem: (key) => Cookies.get(key),
		setItem: (key, value) => {
			const jsonValue = JSON.parse(value)
			const isAuthed = jsonValue?.authentication?.currentUser || false
			if (!isAuthed) {
				Cookies.remove(key)
				Cookies.remove('ReuzerAuthToken')
			} else {
				Cookies.set(key, value, { secure: true }) // { expires: 1, secure: true })
			}
		},
		removeItem: (key) => {
			Cookies.remove(key)
			Cookies.remove('ReuzerAuthToken')
		},
	},
})

const vuexThemePersist = createPersistedState({
	key: 'ReuzerTheme',
	paths: ['theme'],
})

export default new Vuex.Store({
	modules: {
		authentication,
		app,
		theme,
	},
	plugins: [vuexPersist, vuexThemePersist],
})
