export class PlatformModule {
	// Create new instances of the same class as static attributes
	static CMS = new PlatformModule(1, 'CMS')

	constructor(id, name) {
		this.id = id
		this.name = name
	}
}

export const PlatformModules = {
    CMS: PlatformModule.CMS
}