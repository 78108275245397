
var prefix = 'reuzer';
var iconName = 'library';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f513';
var svgPathData = `
M990.329 152.288h-957.127c-18.457 0-33.437 14.979-33.437 33.437v652.016c0 18.457 14.979 33.437 33.437 33.437h957.127c18.457 0 33.437-14.979 33.437-33.437v-652.016c0-18.474-14.963-33.437-33.437-33.437zM956.893 804.304h-890.253v-585.143h890.253v585.143z
M288.743 354.062h517.919c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-517.919c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
M202.911 320.625c0 18.466-14.97 33.437-33.437 33.437s-33.437-14.97-33.437-33.437c0-18.466 14.97-33.437 33.437-33.437s33.437 14.97 33.437 33.437z
M288.743 481.472h446.832c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-446.832c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
M202.911 448.035c0 18.466-14.97 33.437-33.437 33.437s-33.437-14.97-33.437-33.437c0-18.466 14.97-33.437 33.437-33.437s33.437 14.97 33.437 33.437z
M288.743 608.866h565.315c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-565.315c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
M202.911 575.43c0 18.466-14.97 33.437-33.437 33.437s-33.437-14.97-33.437-33.437c0-18.466 14.97-33.437 33.437-33.437s33.437 14.97 33.437 33.437z
M288.743 736.277h517.919c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437h-517.919c-18.457 0-33.437 14.979-33.437 33.437s14.979 33.437 33.437 33.437z
M202.911 702.84c0 18.466-14.97 33.437-33.437 33.437s-33.437-14.97-33.437-33.437c0-18.466 14.97-33.437 33.437-33.437s33.437 14.97 33.437 33.437z
`
export const reuzerLibrary = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};