
var prefix = 'reuzer';
var iconName = 'play';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f511';
var svgPathData = `
M244.975 812.049c-6.246 0-12.476-1.724-17.971-5.12-10.052-6.212-16.162-17.203-16.162-29.013v-531.831c0-11.81 6.11-22.801 16.162-29.013 10.035-6.229 22.596-6.81 33.178-1.536l534.067 265.933c11.588 5.769 18.91 17.596 18.91 30.549s-7.339 24.781-18.91 30.549l-534.067 265.899c-4.813 2.406-10.018 3.584-15.206 3.584zM279.108 301.227v421.581l423.356-210.79-423.356-210.79z
`

export const reuzerPlay = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};