
var prefix = 'reuzer';
var iconName = 'edit';
var width = 60;
var height = 60;
var ligatures = [];
var unicode = 'f516';
var svgPathData = `
M45.618,28.503c-1.104,0-2,0.896-2,2v13.296H16.455V16.636h13.731c1.104,0,2-0.896,2-2s-0.896-2-2-2H14.455
        c-1.104,0-2,0.896-2,2v31.164c0,1.104,0.896,2,2,2h31.163c1.104,0,2-0.896,2-2V30.503C47.618,29.398,46.723,28.503,45.618,28.503z
        M46.868,15.92c0.781-0.781,0.781-2.047,0-2.828c-0.781-0.781-2.047-0.781-2.828,0L23.626,33.506
		l2.828,2.828L46.868,15.92z 
        
        M20.724,39.236 25.724,37.569 22.391,34.236z

`
export const reuzerEdit = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};