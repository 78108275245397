import { httpClient } from '@/services/httpClient'
import { PlatformModule } from '@/services/platformModules'
import { ShowWarningToast, ShowSuccessToast } from '@/services/toastService'
import { router } from '../router'
import Vue from 'vue'

export const authentication = {
	namespaced: true,
	state: {
		currentUser: {
			roles: [],
		},
	},
	getters: {
		redirectToTermsAcceptance: (state) => state?.currentUser?.requiresPlatformTermsAgreement || false,
		isAuthenticated: (state) => (state.currentUser ? !!state.currentUser.email && !!state.currentUser.roles.length > 0 : false),
		profile: (state) => state.currentUser || {},
		isSuperOwner: (state) => (state?.currentUser?.roles || []).includes('SuperOwner'),
		isOwner: (state) => (state?.currentUser?.roles || []).includes('Owner'),
		isAdministrator: (state) => (state?.currentUser?.roles || []).includes('Administrator'),
		isManager: (state) => (state?.currentUser?.roles || []).includes('Manager'),
		isVerifier: (state) => (state?.currentUser?.roles || []).includes('Verifier'),
		isAssessor: (state) => (state?.currentUser?.roles || []).includes('Assessor'),
		isLearner: (state) => (state?.currentUser?.roles || []).includes('Learner'),
		roles: (state) => state?.currentUser?.roles || [],
		permittedModules: (state) => state?.currentUser.platformModules || [],
		hasCMS: (state) => (state?.currentUser?.platformModules && !!state?.currentUser.platformModules.find((x) => x.id === PlatformModule.CMS.id)) || false,
	},
	mutations: {
		setProfile(state, currentUser) {
			state.currentUser = currentUser
		},
		setRequiresPlatformTermsAgreement(state, value) {
			state.currentUser.requiresPlatformTermsAgreement = value
		},
	},
	actions: {
		startSignalR({ getters }) {
			if (getters.isAuthenticated) {
				Vue.prototype.startSignalR()
			}
		},
		async login({ commit, dispatch, getters, rootGetters }, credentials) {
			try {
				const response = await httpClient.makePostRequest('authentication/login', credentials)
				if (response) {
					commit('setProfile', response)
					dispatch('theme/getTheme', null, { root: true })

					if (getters.isAdministrator || getters.isManager) {
						dispatch('app/initialiseAdminModeState', null, { root: true })
					} else {
						dispatch('app/clearAdminMode', null, { root: true })
					}
					Vue.prototype.startSignalR()

					//Redirect to returnUrl or Dashboard
					const isAdminMode = rootGetters['app/isAdminMode'] || false
					const showSplashScreen = response.showSplashScreen || false

					const nextPage = showSplashScreen ? 'splash-screen' : isAdminMode ? 'admin-dashboard' : 'dashboard'
					const url = credentials.returnUrl || nextPage
					let l = router.resolve({ name: url })
					if (l.resolved.matched.length > 0) {
						router.push({ name: url }).catch((e) => console.log(e))
					} else {
						router.push({ name: nextPage }).catch((e) => console.log(e))
					}
				}
			} catch (error) {
				console.log(error)
				ShowWarningToast(error.toString())
			}
		},
		async logout({ commit, dispatch, getters, rootGetters }) {
			try {
				//May fail is cookie has already expired.
				await httpClient
					.makePostRequest('authentication/logout', {
						email: getters.profile.email,
					})
					.then((success) => {
						if (success) {
							Vue.prototype.stopSignalR()
							commit('setProfile', null)

							const ownerCode = rootGetters['theme/ownerCode'] || ''

							if (ownerCode) {
								router.push({ name: 'login-themed', params: { ownerCode: ownerCode } }).catch((e) => console.log(e))
							} else {
								router.push({ name: 'login' }).catch((e) => console.log(e))
							}
						}
					})
			} catch (error) {
				dispatch('logoutUI')
			}
		},
		async logoutUI({ commit, rootGetters }) {
			console.log('logoutUI')
			Vue.prototype.stopSignalR()

			commit('setProfile', null)
			const ownerCode = rootGetters['theme/ownerCode'] || ''
			console.log(ownerCode)
			if (ownerCode) {
				console.log('to login-themed')
				router.push({ name: 'login-themed', params: { ownerCode: ownerCode } }).catch((e) => console.log(e))
			} else {
				console.log('to login')
				router.push({ name: 'login' }).catch((e) => console.log(e))
			}
		},
		async resetPassword({ dispatch }, object) {
			try {
				const result = await httpClient.makePostRequest('authentication/CompletePasswordReset', object)
				if (result) {
					ShowSuccessToast('Password Changed')
				}

				dispatch('logoutUI')
			} catch (error) {
				ShowWarningToast(error)
			}
		},
		async initiatePasswordReset({ dispatch }, object) {
			try {
				await httpClient.makePostRequest('authentication/InitiatePasswordReset', object)

				ShowSuccessToast('An email has been sent to the address provided')

				dispatch('logoutUI')
			} catch (error) {
				ShowWarningToast(error)
			}
		},
		async clearSession({ commit }) {
			Vue.prototype.stopSignalR()
			commit('setProfile', null)
		},
		async acceptTerms({ commit }, termsId) {
			try {
				const result = await httpClient.makePostRequest('authentication/Terms', { termsId: termsId })

				if (result) {
					commit('setRequiresPlatformTermsAgreement', false)
					router.push({ name: 'dashboard' })
				}
			} catch (error) {
				ShowWarningToast(error)
			}
		},
	},
}
