import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, max, min_value, max_value } from 'vee-validate/dist/rules';



export const VeeValidateConfig = {
    install(Vue) {
        Vue.component('ValidationProvider', ValidationProvider);
        Vue.component('ValidationObserver', ValidationObserver);

        //Vee-Validate - Add Validation Rules here...
        extend('min_value', {
            ...min_value,
            params: ['min'],
            message: '{_field_} must be greater than {min}'
        })
        extend('max_value', {
            ...max_value,
            params: ['max'],
            message: '{_field_} must be less than {max}'
        })
        extend('max', {
            ...max,
            params: ['length'],
            message: '{_field_} exceeds the maximum length of {length} characters'
        })
        extend('required', {
            ...required,
            message: '{_field_} is required'
        })
        extend('email', {
            ...email,
            message: 'This is not a valid email address'
        })
        extend('verify_password_complexity', {
            message: `The password must be at least 12 characters long and contain any combination of uppercase, lowercase,number, and special characters (E.g. , . _ & ? etc)`,
            validate: value => {
                var strongRegex = new RegExp("^(?=.*[a-zA-Z0-9.,!@#$%^&*])(?=.{12,})");// new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.,!@#$%^&*])(?=.{8,})");
                return strongRegex.test(value);
            }
        }),
        extend('password', {
            validate: (value, { other }) => value === other,
            message: 'The password confirmation does not match.',
            params: [{ name: 'other', isTarget: true }]
        }),
        extend('email_confirm', {
            validate: (value, { other }) => value === other,
            message: 'The email addresses do not match.',
            params: [{ name: 'other', isTarget: true }]
        })
        extend('lengthBetween', {
            validate: (value, { min, max }) => {
                const length = value && value.length

                return length >= min && length <= max
            },
            params: ['min', 'max'],
            message: 'The {_field_} length must be between {min} and {max} characters'
        })
        extend('valid_dob', {
            message: 'The value entered for {_field_} is not valid',
            validate: (value) => {
                const min = Date.parse('01 Jan 1900 00:00:00 GMT');
                const max = new Date();
                return value >= min && value <= max
            },
        })
        //customise rules here (if required)
        //extend('asyncRule', {
        //   async validate(value) {
        //     // some heavy work/network request.

        //     return !!value;
        //   },
        //   message: 'This field is required' // the error message
        // });

    }
}