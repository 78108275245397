import Vue from "vue"

Vue.filter("uppercase", val => val.toUpperCase())
Vue.filter("percent", val => val + '%')
Vue.filter("truncate", (str, numCharacters) => str.length < numCharacters ? str : `${str.substring(0, numCharacters)}...`)
Vue.filter('round', function(value, decimals) {
    if(!value) {
      value = 0;
    }
  
    if(!decimals) {
      decimals = 0;
    }
  
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    return value;
  });