import  * as signalR from '@microsoft/signalr'
export default {
	install(Vue) {
		// use a new Vue instance as the interface for Vue components to receive/send SignalR events
		// this way every component can listen to events or send new events using this.$notificationHub
		const notificationHub = new Vue()
		Vue.prototype.$notificationHub = notificationHub

		// Provide methods to connect/disconnect from the SignalR hub
		let connection = null
		let manuallyClosed = false

		Vue.prototype.startSignalR = async () => {
			connection = new signalR.HubConnectionBuilder()
				.withUrl(`${process.env.VUE_APP_BASE_URL}signalr-hub`)
				.withAutomaticReconnect()
				.configureLogging(signalR.LogLevel.Information)
				.build()

			// Forward hub events through the event, so we can listen for them in the Vue components
			connection.on('NewNotification', notification => {
				notificationHub.$emit('new-notification', notification)
			})

			connection.on('NewCourseRequest', notification => {
				notificationHub.$emit('new-course-request', notification)
			})

			connection.on('NewCertificateCreated', certificate => {
				notificationHub.$emit('new-certificate-created', certificate)
			})

			connection.on('LogoutUser', user => {
				notificationHub.$emit('logout-user', user)
			})

			connection.on('RefreshCourseLibrary', obj => {
				notificationHub.$emit('refresh-course-library', obj)
			})

			connection.on('UserUploadValidationComplete', obj => {
				console.log(obj);
				notificationHub.$emit('user-upload-validation-complete', obj)
			})

			async function start() {
				try {
					console.log('Starting SignalR')
					await connection.start();
					console.assert(connection.state === signalR.HubConnectionState.Connected);
					console.log("SignalR Connected.");
				} catch (err) {
					console.assert(connection.state === signalR.HubConnectionState.Disconnected);
					console.log(err);
					setTimeout(() => start(), 5000);
				}
			}
		
			connection.onclose(() => {
				console.log('Connection Closed')
				if (!manuallyClosed) start()
			})

			connection.onreconnecting(e => {
				console.log(`SignalR Status: ${connection.state}`, e);
			});

			connection.onreconnected(e => {
				console.log(`SignalR Reconnected`, e);
			});

			connection.onclose(e => {
				console.assert(connection.state === signalR.HubConnectionState.Disconnected);
				console.log(`SignalR Closed and will not reconnect`, e);
				window.location.reload();
			});

			// Start everything
			manuallyClosed = false
			await start()
		}
		Vue.prototype.stopSignalR = () => {
			if (!connection) return;
			console.log('Stopping SignalR')
			connection.stop()
			.catch(err => {
				console.log(err)
			});
		}
	},
}
