
var prefix = 'reuzer';
var iconName = 'padlock';
var width = 60;
var height = 60;
var ligatures = [];
var unicode = 'f516';
var svgPathData = `
M43.409,23.372h-2.648C40.724,17.442,35.912,12.628,30,12.628c-5.913,0-10.725,4.814-10.762,10.744h-2.647
		c-1.104,0-2,0.896-2,2v20c0,1.104,0.896,2,2,2h26.818c1.104,0,2-0.896,2-2v-20C45.409,24.268,44.514,23.372,43.409,23.372z
		 M30,16.628c3.707,0,6.724,3.019,6.761,6.744H23.238C23.275,19.647,26.293,16.628,30,16.628z M41.409,43.372H18.591v-16h22.818
        V43.372z
        
        M27,35.372a3,3 0 1,0 6,0a3,3 0 1,0 -6,0
`
export const reuzerPadlock = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};