import { AccessLevels } from './accessLevels'
import { PlatformModules } from '@/services/platformModules'

export const assessmentRoutes = [
	{
		path: '/assessment',
		component: {
			render(c) {
				return c('router-view')
			},
		},
		meta: { breadCrumb: 'Assessments' },
		children: [
			{
				path: '',
				meta: { accessLevel: [AccessLevels.Assessor, AccessLevels.Verifier], requiredModule: PlatformModules.CMS },
				name: 'assessment-dashboard',
				component: () => import(/* webpackChunkName: "assessment" */ '../views/assessments/Index.vue'),
			},
			{
				path: 'cms-matrix',
				meta: { breadCrumb: 'Matrix', accessLevel: [AccessLevels.Assessor], requiredModule: PlatformModules.CMS },
				props: true,
				name: 'assessor-competence-matrix',
				component: () => import(/* webpackChunkName: "cms" */ '../views/cms/AdminCompetenceMatrix.vue'),
			},
			{
				path: 'view/:assessmentId',
				meta: {
					breadCrumb: 'View',
					accessLevel: [AccessLevels.Assessor, AccessLevels.Administrator, AccessLevels.Manager],
					requiredModule: PlatformModules.CMS,
				},
				name: 'view-assessment',
				props: true,
				component: () => import(/* webpackChunkName: "assessment" */ '../views/assessments/ViewAssessment.vue'),
			},
			{
				path: 'assessment-list',
				meta: { breadCrumb: 'List', accessLevel: [AccessLevels.Assessor], requiredModule: PlatformModules.CMS },
				name: 'assessment-list',
				props: true,
				component: () => import(/* webpackChunkName: "assessment" */ '../views/assessments/AssessmentList.vue'),
			},
			{
				path: 'create',
				meta: { breadCrumb: 'Create', accessLevel: [AccessLevels.Assessor], requiredModule: PlatformModules.CMS },
				name: 'create-new-assessment',
				component: () => import(/* webpackChunkName: "assessment" */ '../views/assessments/CreateNewAssessment.vue'),
			},
			{
				path: 'verification',
				component: {
					render(c) {
						return c('router-view')
					},
				},
				//meta: { breadCrumb: 'Verification Batches' },
				children: [
					{
						path: '',
						meta: { breadCrumb: 'Verification Batches', accessLevel: [AccessLevels.Verifier], requiredModule: PlatformModules.CMS },
						name: 'verification-batch-list',
						component: () => import(/* webpackChunkName: "assessment-verification" */ '../views/assessmentVerification/BatchList.vue'),
					},
					{
						path: 'create',
						meta: { breadCrumb: 'Create', accessLevel: [AccessLevels.Verifier], requiredModule: PlatformModules.CMS },
						name: 'create-verification-batch',
						props: true,
						component: () =>
							import(/* webpackChunkName: "assessment-verification" */ '../views/assessmentVerification/CreateVerificationBatch.vue'),
					},
					{
						path: ':batchId',
						component: {
							render(c) {
								return c('router-view')
							},
						},
						meta: { breadCrumb: 'Verification Batch' },
						children: [
							{
								path: '',
								meta: { breadCrumb: 'View', accessLevel: [AccessLevels.Verifier], requiredModule: PlatformModules.CMS },
								name: 'view-verification-batch',
								props: ({ params }) => ({ batchId: Number.parseInt(params.batchId, 10) || 0 }),
								component: () =>
									import(/* webpackChunkName: "assessment-verification" */ '../views/assessmentVerification/ViewVerificationBatch.vue'),
							},
							{
								path: 'verify/:assessmentVerificationId',
								meta: { breadCrumb: 'Verify', accessLevel: [AccessLevels.Verifier], requiredModule: PlatformModules.CMS },
								name: 'verify-assessment',
								props: ({ params }) => ({ ...params, ...{ batchId: Number.parseInt(params.batchId, 10) || undefined } }),
								component: () =>
									import(/* webpackChunkName: "assessment-verification" */ '../views/assessmentVerification/VerifyAssessment.vue'),
							},
						],
					},
				],
			},
		],
	},
]
