
var prefix = 'reuzer';
var iconName = 'magnifying-glass';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f512';
var svgPathData = `
M802.918 752.401l-123.546-123.546c35.994-43.81 57.651-99.226 57.651-159.539 0-141.158-117.965-256-262.98-256s-262.98 114.842-262.98 256 117.982 256 262.98 256c58.146 0 111.753-18.688 155.341-49.92l125.252 125.252c6.656 6.673 15.394 10.001 24.132 10.001s17.476-3.328 24.132-10.001c13.346-13.312 13.346-34.918 0.017-48.247zM279.347 469.333c0-103.509 87.347-187.733 194.714-187.733s194.714 84.224 194.714 187.733c0 103.526-87.347 187.733-194.714 187.733s-194.714-84.207-194.714-187.733z
`

export const reuzerMagnifyingGlass = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};