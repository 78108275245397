
var prefix = 'reuzer';
var iconName = 'download';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f510';
var svgPathData = `
M779.025 493.227c-18.842 0-34.133 15.292-34.133 34.133v212.378h-465.783v-212.378c0-18.842-15.292-34.133-34.133-34.133s-34.133 15.292-34.133 34.133v246.511c0 18.842 15.292 34.133 34.133 34.133h534.067c18.842 0 34.133-15.292 34.133-34.133v-246.511c-0.017-18.859-15.292-34.133-34.15-34.133z
M482.304 582.895c6.741 7.407 15.991 11.162 25.259 11.162 1.485 0 2.953-0.205 4.42-0.393 1.468 0.188 2.953 0.393 4.42 0.393 9.284 0 18.534-3.772 25.259-11.162l114.603-125.935c12.681-13.943 11.674-35.533-2.287-48.23-13.943-12.681-35.533-11.639-48.213 2.27l-59.631 65.553v-226.423c0-18.842-15.292-34.133-34.133-34.133s-34.133 15.292-34.133 34.133v226.441l-59.665-65.57c-12.681-13.909-34.27-14.967-48.213-2.27s-14.967 34.27-2.287 48.23l114.603 125.935z
`

export const reuzerDownload = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};