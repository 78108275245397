var prefix = 'reuzer'
var iconName = 'otj'
var width = 1024
var height = 1024
var ligatures = []
var unicode = 'f505'
var svgPathData = `
M505.578 169.757c-49.677 0-90.026 34.955-102.346 80.993-178.201 59.072-307.712 222.351-317.248 417.664l-42.706 16.466c-1.551 0.605-2.842 1.221-4.082 1.92l0.16-0.083c-24.175 13.321-39.282 38.881-39.282 66.488v69.165c0.002 17.603 14.272 31.872 31.874 31.874h1034.262c17.603-0.002 31.872-14.272 31.874-31.874v0-69.165c0-27.606-15.107-53.166-39.282-66.488-1.080-0.614-2.371-1.232-3.706-1.762l-0.216-0.075-42.706-16.466c-9.536-195.313-139.047-358.593-317.248-417.664-12.321-46.038-52.669-80.993-102.346-80.993zM505.578 233.505h87c23.849 0 43.202 18.907 44.107 42.457 0.555 13.969 10.017 25.586 22.84 29.359l0.226 0.057c167.662 48.026 290.572 202.166 290.572 385.665 0 0.003 0 0.007 0 0.011 0 13.484 8.372 25.014 20.203 29.672l0.216 0.075 57.555 22.193c3.653 2.221 6.039 5.9 6.039 10.209v37.291h-970.514v-37.291c0-4.309 2.385-7.989 6.039-10.209l57.555-22.193c12.047-4.734 20.42-16.262 20.42-29.747 0-0.003 0-0.008 0-0.011v0.001c0-183.499 122.91-337.639 290.572-385.665 13.047-3.829 22.51-15.446 23.064-29.354l0.002-0.061c0.905-23.551 20.258-42.457 44.107-42.457z

M668 425.030c-4.733-2.937-10.475-4.678-16.624-4.678-11.46 0-21.507 6.048-27.124 15.125l-0.078 0.136-118.688 194.264-81.335-54.94c-5.005-3.424-11.19-5.469-17.853-5.469-10.945 0-20.6 5.516-26.34 13.919l-0.071 0.11c-3.424 5.005-5.469 11.19-5.469 17.853 0 10.945 5.516 20.6 13.919 26.34l0.11 0.071 108.977 73.615c5.005 3.423 11.189 5.466 17.85 5.466 11.451 0 21.492-6.038 27.113-15.104l0.078-0.136 136.119-222.777c2.928-4.728 4.665-10.462 4.665-16.601 0-11.453-6.041-21.497-15.112-27.115l-0.136-0.078z


`

export const reuzerOTJ = {
	prefix: prefix,
	iconName: iconName,
	icon: [width, height, ligatures, unicode, svgPathData],
}
