
var prefix = 'reuzer';
var iconName = 'logout';
var width = 60;
var height = 60;
var ligatures = [];
var unicode = 'f514';
var svgPathData = `
M29.102,47.584H14.657c-1.104,0-2-0.896-2-2V14.29c0-1.104,0.896-2,2-2h14.444c1.104,0,2,0.896,2,2
	s-0.896,2-2,2H16.657v27.293h12.444c1.104,0,2,0.896,2,2S30.206,47.584,29.102,47.584z M35.965,22.961
	c0,0.543,0.221,1.085,0.654,1.48l3.842,3.496H27.192c-1.104,0-2,0.896-2,2s0.896,2,2,2h13.269l-3.842,3.496
	c-0.434,0.395-0.654,0.936-0.654,1.479c0,0.48,0.172,0.962,0.521,1.346c0.743,0.817,2.009,0.876,2.825,0.133l7.38-6.715
	c0.501-0.457,0.714-1.108,0.635-1.733c0.084-0.629-0.13-1.287-0.635-1.746l-7.38-6.715c-0.816-0.743-2.082-0.684-2.825,0.133
	C36.137,21.999,35.965,22.481,35.965,22.961z
`
export const reuzerLogout = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};