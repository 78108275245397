
var prefix = 'reuzer';
var iconName = 'dashboard';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f500';
var svgPathData =
    `
  M512 240.299c-282.31 0-512 229.69-512 512 0 17.326 14.061 31.387 31.387 31.387s31.387-14.061 31.387-31.387c0-247.706 201.52-449.226 449.226-449.226s449.226 201.52 449.226 449.226c0 17.326 14.061 31.387 31.387 31.387s31.387-14.061 31.387-31.387c0-282.31-229.69-512-512-512z
  M701.499 401c-15.285-8.255-34.306-2.526-42.529 12.728l-174.59 323.678c-8.223 15.254-2.526 34.306 12.728 42.529 4.74 2.558 9.84 3.767 14.878 3.767 11.174 0 21.987-5.979 27.652-16.494l174.59-323.678c8.223-15.254 2.511-34.306-12.728-42.529z
  M168.705 726.797c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
  M543.387 381.54c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
  M918.069 726.797c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
  M859.218 554.168c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
  M227.555 554.168c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
  M368.797 428.62c0 17.334-14.053 31.387-31.387 31.387s-31.387-14.053-31.387-31.387c0-17.334 14.053-31.387 31.387-31.387s31.387 14.053 31.387 31.387z
   `


export const reuzerDashboard = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};