import axios from 'axios'
import { ShowWarningToast } from '../services/toastService'
import { router } from '../router'
import store from '../store'
import Cookies from 'js-cookie'

//Expose create client
export function createHttpClient(isFormData = false) {
	const contentType = isFormData ? 'multipart/form-data' : 'application/json'
	const client = axios.create({
		baseURL: `${process.env.VUE_APP_BASE_URL}api/`,
		withCredentials: true,
		headers: {
			'Content-Type': contentType,
		},
	})

	client.interceptors.response.use(interceptResponse, interceptError)

	//XSRF
	client.interceptors.request.use(appendXsrfToken, (error) => Promise.reject(error))

	function appendXsrfToken(config) {
		const xsrfToken = Cookies.get('Reuzer-XSRF-TOKEN')

		if (xsrfToken) {
			config.headers['X-XSRF-TOKEN'] = xsrfToken
		}

		return config
	}

	return client
}

function interceptResponse(res) {
	//console.log(`Request Success: ${res.status}`, res)
	return res
}

function interceptError(err) {
	if (!err.response) {
		console.log(err)
		throw new Error(`Could not connect to Server`)
	} else {
		switch (err.response.status) {
			case 400: {
				const errors = err.response.data.errors
				const output = []
				output.push(err.response.data.title)
				for (const key in errors) {
					output.push(`${key} : ${errors[key].join(', ')}`)
				}
				ShowWarningToast(`${output.join('\n')}`)

				throw new Error(err)
			}
			case 401: {
				ShowWarningToast('Session Expired')
				store.dispatch('authentication/logoutUI')
				throw new Error(`Authentication Error`)
			}
			case 404: {
				ShowWarningToast('Page Not Found')
				router.push({ path: '/error/404' })
				throw new Error(`${err.config.url} not found`)
			}
			default: {
				ShowWarningToast('An unknown error has occured')
				router.push({ path: '/error/500' })
				throw new Error(`Unknown Error`)
			}
		}
	}
}

async function makePostRequest(url, data, isFormData = false) {
	const client = createHttpClient(isFormData)
	const response = await client.post(url, data)
	return processResponse(response.data)
}

async function makePutRequest(url, data, isFormData = false) {
	const client = createHttpClient(isFormData)
	const response = await client.put(url, data)
	return processResponse(response.data)
}

async function makeGetRequest(url, throwError = false) {
	const client = createHttpClient()
	const response = await client.get(url)
	return processResponse(response.data, throwError)
}

async function makeDeleteRequest(url) {
	const httpClient = createHttpClient()
	const response = await httpClient.delete(url)
	return processResponse(response.data)
}

function processResponse(response, throwError = false) {
	if (response.isSuccess) {
		return response.data
	} else {
		//200 repsonse where failure deliberately returned.
		console.log(response)
		const error = response.errors.join('\n')
		ShowWarningToast(error)

		if (throwError) throw error
	}
}

export const httpClient = {
	// fileDownloadRequest,
	makePostRequest,
	makeGetRequest,
	makePutRequest,
	makeDeleteRequest,
}
