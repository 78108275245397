
var prefix = 'reuzer';
var iconName = 'users';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f504';
var svgPathData = `
    M622.445 540.598c38.67-31.517 63.352-79.182 63.352-132.429 0-68.538-41.003-127.67-99.976-155.175-1.221-0.73-2.411-1.522-3.759-2.11-5.916-2.554-12.023-4.679-18.21-6.566-0.445-0.143-0.904-0.254-1.364-0.381-3.315-0.983-6.614-2.030-9.977-2.807-1.142-0.27-2.331-0.364-3.474-0.603-3.188-0.698-6.44-1.174-9.708-1.697-2.855-0.445-5.662-0.983-8.55-1.285-5.393-0.587-10.881-0.857-16.417-0.952-0.746-0.016-1.491-0.127-2.237-0.127-0.064 0-0.111 0.016-0.175 0.016s-0.111-0.016-0.175-0.016c-0.746 0-1.491 0.127-2.237 0.127-5.535 0.079-11.008 0.364-16.417 0.952-2.871 0.301-5.695 0.84-8.55 1.285-3.252 0.507-6.504 1-9.708 1.697-1.158 0.254-2.331 0.333-3.474 0.603-3.362 0.793-6.662 1.824-9.977 2.807-0.445 0.143-0.904 0.237-1.364 0.381-6.186 1.903-12.293 4.013-18.21 6.566-1.348 0.587-2.538 1.364-3.759 2.11-58.974 27.521-99.976 86.636-99.976 155.175 0 53.248 24.681 100.912 63.352 132.429-78.849 39.67-133.238 120.295-133.952 213.197 0 0.333 0 0.682 0 1.031 0 18.067 14.625 32.723 32.707 32.723h0.016c18.035 0 32.659-14.577 32.738-32.596 0.46-96.487 80.72-174.971 179.031-175.050 98.311 0.095 178.57 78.579 178.936 175.050 0.079 18.019 14.704 32.596 32.738 32.596h0.016c18.083 0 32.707-14.656 32.707-32.723 0-0.349 0-0.698 0-1.031-0.713-92.902-55.104-173.527-133.952-213.197zM512 514.411c-59.751-0.095-108.335-47.712-108.335-106.241 0-43.922 27.346-81.704 66.239-97.883 4.473-1.84 9.089-3.331 13.784-4.552 1.824-0.491 3.616-0.983 5.472-1.38 1.618-0.333 3.252-0.587 4.886-0.857 2.078-0.349 4.156-0.713 6.265-0.936 3.14-0.333 6.313-0.491 9.485-0.555 0.746-0.016 1.459-0.111 2.204-0.111s1.459 0.095 2.204 0.111c3.173 0.064 6.329 0.222 9.485 0.539 2.125 0.222 4.188 0.603 6.265 0.936 1.634 0.27 3.267 0.524 4.886 0.857 1.856 0.397 3.664 0.904 5.472 1.38 4.695 1.237 9.311 2.728 13.784 4.552 38.893 16.179 66.239 53.961 66.239 97.883 0 58.562-48.584 106.179-108.335 106.258z
    M890.047 540.598c38.67-31.517 63.352-79.182 63.352-132.429 0-94.679-78.040-171.719-173.971-171.719-13.625 0-27.219 1.57-40.368 4.664-10.088 2.364-20.033 5.662-29.534 9.771-16.591 7.201-24.205 26.473-17.019 43.049s26.457 24.19 43.049 17.004c5.932-2.57 12.134-4.616 18.463-6.107 8.263-1.935 16.813-2.934 25.41-2.934 59.83 0 108.51 47.68 108.51 106.273s-48.679 106.258-108.51 106.258c-18.067 0-32.723 14.656-32.723 32.723 0 18.083 14.656 32.738 32.723 32.738 98.39 0 178.745 78.531 179.11 175.066 0.079 18.019 14.704 32.596 32.738 32.596h0.016c18.083 0 32.707-14.656 32.707-32.723 0-0.349 0-0.698 0-1.031-0.713-92.902-55.104-173.527-133.952-213.197z
    M277.294 547.15c0-18.067-14.656-32.723-32.723-32.723-59.83 0-108.51-47.665-108.51-106.258s48.679-106.273 108.51-106.273c8.597 0 17.146 1 25.41 2.934 6.329 1.491 12.531 3.537 18.463 6.107 16.591 7.186 35.863-0.428 43.049-17.004s-0.428-35.848-17.004-43.049c-9.517-4.108-19.462-7.408-29.55-9.771-13.149-3.093-26.743-4.664-40.368-4.664-95.932 0-173.971 77.040-173.971 171.719 0 53.248 24.681 100.912 63.352 132.429-78.849 39.67-133.238 120.295-133.952 213.197 0 0.333 0 0.682 0 1.031 0 18.067 14.625 32.723 32.707 32.723h0.016c18.035 0 32.659-14.577 32.738-32.596 0.364-96.534 80.72-175.066 179.11-175.066 18.067 0 32.723-14.656 32.723-32.738z
`

export const reuzerUsers = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};