
var prefix = 'reuzer';
var iconName = 'bell';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f506';
var svgPathData = `
    M775.134 661.811l-79.701-269.807c-4.489-97.775-84.855-176.009-183.279-176.009-98.458 0-178.842 78.251-183.296 176.043l-79.974 269.739c-3.055 10.325-1.058 21.487 5.376 30.123s16.589 13.722 27.358 13.722h114.176c7.578 57.685 56.747 102.4 116.224 102.4s108.646-44.715 116.224-102.4h114.176c10.769 0 20.907-5.069 27.341-13.705s8.431-19.78 5.376-30.106zM512 739.738c-21.692 0-39.919-14.387-46.387-34.133h92.791c-6.485 19.746-24.713 34.133-46.404 34.133zM327.322 637.338l65.775-221.85c2.219-4.54 3.584-9.557 3.584-14.95 0-64.119 51.797-116.275 115.456-116.275s115.456 52.156 115.456 116.275c0 5.41 1.365 10.445 3.601 14.985l65.519 221.815h-369.391z
`

export const reuzerBell = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};