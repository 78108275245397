
var prefix = 'reuzer';
var iconName = 'report';
var width = 1024;
var height = 1024;
var ligatures = [];
var unicode = 'f503';
var svgPathData = 
    `
        M990.564 804.572h-923.69v-618.579c0-18.457-14.979-33.437-33.437-33.437s-33.437 14.979-33.437 33.437v652.016c0 18.457 14.979 33.437 33.437 33.437h957.127c18.457 0 33.437-14.979 33.437-33.437s-14.979-33.437-33.437-33.437z
        M236.148 735.86c18.457 0 33.437-14.979 33.437-33.437v-211.32c0-18.457-14.979-33.437-33.437-33.437s-33.437 14.979-33.437 33.437v211.32c0 18.457 14.979 33.437 33.437 33.437z
        M436.768 735.86c18.457 0 33.437-14.979 33.437-33.437v-328.349c0-18.457-14.979-33.437-33.437-33.437s-33.437 14.979-33.437 33.437v328.349c0 18.457 14.979 33.437 33.437 33.437z
        M637.388 735.86c18.457 0 33.437-14.979 33.437-33.437v-211.32c0-18.457-14.979-33.437-33.437-33.437s-33.437 14.979-33.437 33.437v211.32c0 18.457 14.979 33.437 33.437 33.437z
        M838.009 735.86c18.457 0 33.437-14.979 33.437-33.437v-516.431c0-18.457-14.979-33.437-33.437-33.437s-33.437 14.979-33.437 33.437v516.431c0 18.457 14.979 33.437 33.437 33.437z
    `


export const reuzerReport = {
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};