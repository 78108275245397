import { AccessLevels } from './accessLevels'

export const clientRoutes = {
	path: '/clients',
	component: {
		render(c) {
			return c('router-view')
		},
	},
	meta: { breadCrumb: 'Client List' },
	children: [
		{
			path: '',
			name: 'client-list',
			component: () => import(/* webpackChunkName: "clients" */ '../views/clients/Index.vue'),
			meta: { accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
		},
		{
			path: ':clientId',
			component: () => import(/* webpackChunkName: "client" */ '../views/clients/ViewClient.vue'),
			name: 'view-client',
			props: true,
			meta: { breadCrumb: 'View Client', accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
		},
		{
			path: ':clientId/create-user',
			component: () => import(/* webpackChunkName: "client" */ '../views/clients/CreateUser.vue'),
			name: 'client-create-user',
			props: true,
			meta: { breadCrumb: 'Create User', accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
		},
		{
			path: ':clientId/edit-user/:userId',
			component: () => import(/* webpackChunkName: "client" */ '../views/clients/EditUser.vue'),
			name: 'client-edit-user',
			props: (route) => ({ clientId: route.params.clientId, userId: route.params.userId }),
			meta: { breadCrumb: 'Edit User', accessLevel: [AccessLevels.Owner, AccessLevels.SuperOwner] },
		},
	],
}
